import { createReducer, on } from '@florencecard-lib/dispatchable';
import { addDays, format } from 'date-fns';
import produce from 'immer';
import {
  defaultLetterAccountsInfo,
  defaultLetterAttendsInfo,
  defaultLetterGroomInfo,
  defaultLetterGuestBooksInfo,
  defaultLetterOGInfo,
  defaultLetterPhotoInfo,
  defaultLetterPlaceInfo,
  defaultLetterPriestInfo,
  defaultLetterThemeInfo,
  defaultLetterWayToComeInfo,
  isLetterOGTitleVariant,
  isLetterTheme,
  LetterAccountsInfo,
  LetterAttendsInfo,
  LetterGroomInfo,
  LetterGuestBooksInfo,
  LetterNoteInfo,
  LetterOGInfo,
  LetterPhotoGalleryType,
  LetterPhotoInfo,
  LetterPlaceInfo,
  LetterPriestInfo,
  letterStorageKeys,
  LetterThemeInfo,
  LetterWayToComeInfo,
  validateAccountsInfo,
  validateAttendsInfo,
  validateGroomInfo,
  validateNoteInfo,
  validateOGInfo,
  validatePhotoInfo,
  validatePlaceInfo,
  validatePriestInfo,
  validateThemeInfo,
} from '~/models';
import {
  appendLetterPhotoGalleryAction,
  deleteLetterPhotoGalleryAction,
  enableLetterAdditionalAccountsInfoAction,
  naverstoreAction,
  toggleLetterAccountsInfoAction,
  toggleLetterAttendsEnabledAction,
  toggleLetterGuestBooksEnabledAction,
  updateLetterAccountsDescriptionAction,
  updateLetterAccountsTitleAction,
  updateLetterAdditionalGroomAccountInfoAction,
  updateLetterAdditionalPriestAccountInfoAction,
  updateLetterAttendsDescriptionAction,
  updateLetterAttendsTitleAction,
  updateLetterEmailAction,
  updateLetterGroomAccountInfoAction,
  updateLetterGroomInfoAction,
  updateLetterNoteInfoAction,
  updateLetterOGInfoAction,
  updateLetterPhotoGalleryAction,
  updateLetterPhotoGalleryTypeAction,
  updateLetterPhotoInfoAction,
  updateLetterPlaceInfoAction,
  updateLetterPriestAccountInfoAction,
  updateLetterPriestInfoAction,
  updateLetterThemeAction,
  updateLetterWayToComeInfoAction,
  updateLetterYoutubeLinkAction,
  updateLetterYoutubeLiveLinkAction,
} from '~/store/actions';
import type { Action } from '~/store/seed-work';
import { createLocalStorage } from '~/utils/storage';
import { isEmail } from '~/utils/validator';

const storage = createLocalStorage();

export type LetterState = Readonly<{
  email: string | null;
  emailValid: boolean;
  groom: LetterGroomInfo;
  groomValid: boolean;
  priest: LetterPriestInfo;
  priestValid: boolean;
  place: LetterPlaceInfo;
  placeValid: boolean;
  note: LetterNoteInfo;
  noteValid: boolean;
  photo: LetterPhotoInfo;
  photoValid: boolean;
  wayToCome: LetterWayToComeInfo;
  accounts: LetterAccountsInfo;
  accountsValid: boolean;
  theme: LetterThemeInfo;
  themeValid: boolean;
  og: LetterOGInfo;
  ogValid: boolean;
  attends: LetterAttendsInfo;
  attendsValid: boolean;
  guestBooks: LetterGuestBooksInfo;
  youtubeLink: string;
  youtubeLiveLink: string;
}>;

export type LetterStateWithRoot = Readonly<{
  letter: LetterState;
}>;

const initialEmail = storage.get(letterStorageKeys.email);

const initialLetterGroomInfo: LetterState['groom'] = {
  name: storage.get(letterStorageKeys.groom.name) ?? defaultLetterGroomInfo.name,
  showPhone:
    storage.get(letterStorageKeys.groom.showPhone) != null
      ? storage.get(letterStorageKeys.groom.showPhone) === 'true'
      : defaultLetterGroomInfo.showPhone,
  phone1: storage.get(letterStorageKeys.groom.phone1) ?? defaultLetterGroomInfo.phone1,
  phone2: storage.get(letterStorageKeys.groom.phone2) ?? defaultLetterGroomInfo.phone2,
  phone3: storage.get(letterStorageKeys.groom.phone3) ?? defaultLetterGroomInfo.phone3,
  order: storage.get(letterStorageKeys.groom.order) ?? defaultLetterGroomInfo.order,
  fatherName: storage.get(letterStorageKeys.groom.fatherName) ?? defaultLetterGroomInfo.fatherName,
  motherName: storage.get(letterStorageKeys.groom.motherName) ?? defaultLetterGroomInfo.motherName,
  requireParentPhone: storage.get(letterStorageKeys.groom.requireParentPhone) === 'true',
  fatherPhone1:
    storage.get(letterStorageKeys.groom.fatherPhone1) ?? defaultLetterGroomInfo.fatherPhone1,
  fatherPhone2:
    storage.get(letterStorageKeys.groom.fatherPhone2) ?? defaultLetterGroomInfo.fatherPhone2,
  fatherPhone3:
    storage.get(letterStorageKeys.groom.fatherPhone3) ?? defaultLetterGroomInfo.fatherPhone3,
  motherPhone1:
    storage.get(letterStorageKeys.groom.motherPhone1) ?? defaultLetterGroomInfo.motherPhone1,
  motherPhone2:
    storage.get(letterStorageKeys.groom.motherPhone2) ?? defaultLetterGroomInfo.motherPhone2,
  motherPhone3:
    storage.get(letterStorageKeys.groom.motherPhone3) ?? defaultLetterGroomInfo.motherPhone3,
};

const initialLetterPriestInfo: LetterState['priest'] = {
  name: storage.get(letterStorageKeys.priest.name) ?? defaultLetterPriestInfo.name,
  showPhone:
    storage.get(letterStorageKeys.priest.showPhone) != null
      ? storage.get(letterStorageKeys.priest.showPhone) === 'true'
      : defaultLetterPriestInfo.showPhone,
  phone1: storage.get(letterStorageKeys.priest.phone1) ?? defaultLetterPriestInfo.phone1,
  phone2: storage.get(letterStorageKeys.priest.phone2) ?? defaultLetterPriestInfo.phone2,
  phone3: storage.get(letterStorageKeys.priest.phone3) ?? defaultLetterPriestInfo.phone3,
  order: storage.get(letterStorageKeys.priest.order) ?? defaultLetterPriestInfo.order,
  fatherName:
    storage.get(letterStorageKeys.priest.fatherName) ?? defaultLetterPriestInfo.fatherName,
  motherName:
    storage.get(letterStorageKeys.priest.motherName) ?? defaultLetterPriestInfo.motherName,
  requireParentPhone: storage.get(letterStorageKeys.priest.requireParentPhone) === 'true',
  fatherPhone1:
    storage.get(letterStorageKeys.priest.fatherPhone1) ?? defaultLetterPriestInfo.fatherPhone1,
  fatherPhone2:
    storage.get(letterStorageKeys.priest.fatherPhone2) ?? defaultLetterPriestInfo.fatherPhone2,
  fatherPhone3:
    storage.get(letterStorageKeys.priest.fatherPhone3) ?? defaultLetterPriestInfo.fatherPhone3,
  motherPhone1:
    storage.get(letterStorageKeys.priest.motherPhone1) ?? defaultLetterPriestInfo.motherPhone1,
  motherPhone2:
    storage.get(letterStorageKeys.priest.motherPhone2) ?? defaultLetterPriestInfo.motherPhone2,
  motherPhone3:
    storage.get(letterStorageKeys.priest.motherPhone3) ?? defaultLetterPriestInfo.motherPhone3,
};

const initialLetterPlaceInfo: LetterState['place'] = {
  date: storage.get(letterStorageKeys.place.date) ?? defaultLetterPlaceInfo.date,
  time: storage.get(letterStorageKeys.place.time) ?? defaultLetterPlaceInfo.time,
  zipCode: storage.get(letterStorageKeys.place.zipCode) ?? defaultLetterPlaceInfo.zipCode,
  address: storage.get(letterStorageKeys.place.address) ?? defaultLetterPlaceInfo.address,
  addressDetail:
    storage.get(letterStorageKeys.place.addressDetail) ?? defaultLetterPlaceInfo.addressDetail,
  x: storage.get(letterStorageKeys.place.x) ?? undefined,
  y: storage.get(letterStorageKeys.place.y) ?? undefined,
  showPlace:
    storage.get(letterStorageKeys.place.showPlace) != null
      ? storage.get(letterStorageKeys.place.showPlace) === 'true'
      : defaultLetterPlaceInfo.showPlace,
  phone1: storage.get(letterStorageKeys.place.phone1) ?? defaultLetterPlaceInfo.phone1,
  phone2: storage.get(letterStorageKeys.place.phone2) ?? defaultLetterPlaceInfo.phone2,
  phone3: storage.get(letterStorageKeys.place.phone3) ?? defaultLetterPlaceInfo.phone3,
};

function getInitialLetterWayToComeInfo() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const val = JSON.parse(storage.get(letterStorageKeys.wayToCome)!);

    return val ?? defaultLetterWayToComeInfo;
  } catch {
    //
  }

  return defaultLetterWayToComeInfo;
}

const initialLetterNoteInfo: LetterState['note'] = {
  note1: storage.get(letterStorageKeys.note.note1) ?? '0',
  note2: storage.get(letterStorageKeys.note.note2) ?? '0',
};

function getInitialLetterPhotoInfo() {
  let gallery = defaultLetterPhotoInfo.gallery;

  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const val = JSON.parse(storage.get(letterStorageKeys.photo.gallery)!);
    if (Array.isArray(val)) {
      gallery = val;
    }
  } catch {
    //
  }

  const initialLetterPhotoInfo: LetterState['photo'] = {
    mainImage: storage.get(letterStorageKeys.photo.main) ?? defaultLetterPhotoInfo.mainImage,
    secondImage: storage.get(letterStorageKeys.photo.second) ?? defaultLetterPhotoInfo.secondImage,
    lastImage: storage.get(letterStorageKeys.photo.last) ?? defaultLetterPhotoInfo.lastImage,
    gallery,
    galleryType:
      (storage.get(letterStorageKeys.photo.galleryType) as LetterPhotoGalleryType) ??
      defaultLetterPhotoInfo.galleryType,
  };

  return initialLetterPhotoInfo;
}

function getInitialLetterAccountsInfo() {
  let info: LetterAccountsInfo = defaultLetterAccountsInfo;

  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const val: LetterAccountsInfo = JSON.parse(storage.get(letterStorageKeys.accounts)!);
    if (val != null && validateAccountsInfo(val)) {
      info = val;
    } else {
      info = defaultLetterAccountsInfo;
    }
  } catch {
    //
  }

  return info;
}

const initialLetterPhotoInfo = getInitialLetterPhotoInfo();
const initialLetterAccountsInfo = getInitialLetterAccountsInfo();

const initialLetterThemeInfo: LetterState['theme'] = (() => {
  const theme = storage.get(letterStorageKeys.theme.theme);

  if (isLetterTheme(theme)) {
    return { theme };
  }

  return defaultLetterThemeInfo;
})();

const initialLetterOGInfo: LetterState['og'] = (() => {
  const title = storage.get(letterStorageKeys.og.title);

  if (isLetterOGTitleVariant(title)) {
    return { title };
  }

  return defaultLetterOGInfo;
})();

function getInitialLetterAttendsInfo() {
  let info: LetterAttendsInfo = defaultLetterAttendsInfo;

  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const val: LetterAttendsInfo = JSON.parse(storage.get(letterStorageKeys.attends)!);
    if (val != null && validateAttendsInfo(val)) {
      info = val;
    } else {
      info = defaultLetterAttendsInfo;
    }
  } catch {
    //
  }

  return info;
}

const initialLetterAttendsInfo = getInitialLetterAttendsInfo();

function getInitialLetterGuestBooksInfo() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const val = JSON.parse(storage.get(letterStorageKeys.guestBooks)!) as LetterGuestBooksInfo;

    return val ?? defaultLetterGuestBooksInfo;
  } catch {
    //
  }

  return defaultLetterGuestBooksInfo;
}

const initialYoutubeLink = storage.get(letterStorageKeys.youtubeLink);
const initialYoutubeLiveLink = storage.get(letterStorageKeys.youtubeLiveLink);

export const letterReducer = createReducer<LetterState, Action>(
  {
    email: initialEmail,
    emailValid: isEmail(initialEmail),
    groom: initialLetterGroomInfo,
    groomValid: validateGroomInfo(initialLetterGroomInfo),
    priest: initialLetterPriestInfo,
    priestValid: validatePriestInfo(initialLetterPriestInfo),
    place: initialLetterPlaceInfo,
    placeValid: validatePlaceInfo(initialLetterPlaceInfo),
    wayToCome: getInitialLetterWayToComeInfo(),
    note: initialLetterNoteInfo,
    noteValid: validateNoteInfo(initialLetterNoteInfo),
    photo: initialLetterPhotoInfo,
    photoValid: validatePhotoInfo(initialLetterPhotoInfo),
    accounts: initialLetterAccountsInfo,
    accountsValid: validateAccountsInfo(initialLetterAccountsInfo),
    theme: initialLetterThemeInfo,
    themeValid: validateThemeInfo(initialLetterThemeInfo),
    og: initialLetterOGInfo,
    ogValid: validateOGInfo(initialLetterOGInfo),
    attends: initialLetterAttendsInfo,
    attendsValid: validateAttendsInfo(initialLetterAttendsInfo),
    guestBooks: getInitialLetterGuestBooksInfo(),
    youtubeLink: initialYoutubeLink ?? '',
    youtubeLiveLink: initialYoutubeLiveLink ?? '',
  },
  on(updateLetterEmailAction, (state, action) =>
    produce(state, (draft) => {
      draft.email = action.email;
      draft.emailValid = isEmail(draft.email);
    }),
  ),
  on(updateLetterGroomInfoAction, (state, action) =>
    produce(state, (draft) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draft.groom[action.key] = action.value;
      draft.groomValid = validateGroomInfo(draft.groom);
    }),
  ),
  on(updateLetterPriestInfoAction, (state, action) =>
    produce(state, (draft) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draft.priest[action.key] = action.value;
      draft.priestValid = validateGroomInfo(draft.priest);
    }),
  ),
  on(updateLetterPlaceInfoAction, (state, action) =>
    produce(state, (draft) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draft.place[action.key] = action.value;
      draft.placeValid = validatePlaceInfo(draft.place);
    }),
  ),
  on(updateLetterWayToComeInfoAction, (state, action) =>
    produce(state, (draft) => {
      draft.wayToCome = {
        ...draft.wayToCome,
        [action.key]: action.value,
      };
    }),
  ),
  on(updateLetterNoteInfoAction, (state, action) =>
    produce(state, (draft) => {
      draft.note[action.key] = action.value;
      draft.noteValid = validateNoteInfo(draft.note);
    }),
  ),
  on(updateLetterPhotoInfoAction, (state, action) =>
    produce(state, (draft) => {
      draft.photo[action.key] = action.value;
      draft.photoValid = validatePhotoInfo(draft.photo);
    }),
  ),
  on(appendLetterPhotoGalleryAction, (state, action) =>
    produce(state, (draft) => {
      draft.photo.gallery.push(action.imageUrl);
      draft.photoValid = validatePhotoInfo(draft.photo);
    }),
  ),
  on(updateLetterPhotoGalleryAction, (state, action) =>
    produce(state, (draft) => {
      const index = draft.photo.gallery.findIndex((x) => x === action.prevImageUrl);

      if (index > -1) {
        draft.photo.gallery[index] = action.nextImageUrl;
        draft.photoValid = validatePhotoInfo(draft.photo);
      }
    }),
  ),
  on(updateLetterPhotoGalleryTypeAction, (state, action) =>
    produce(state, (draft) => {
      draft.photo.galleryType = action.galleryType;
      draft.photoValid = validatePhotoInfo(draft.photo);
    }),
  ),
  on(deleteLetterPhotoGalleryAction, (state, action) =>
    produce(state, (draft) => {
      const index = draft.photo.gallery.findIndex((x) => x === action.imageUrl);

      draft.photo.gallery.splice(index, 1);
      draft.photoValid = validatePhotoInfo(draft.photo);
    }),
  ),
  on(toggleLetterAccountsInfoAction, (state, action) =>
    produce(state, (draft) => {
      const initialData = {
        name: '',
        bankName: '',
        no: '',
      };

      draft.accounts = {
        title: action.needed ? '0' : null,
        description: action.needed ? '0' : null,
        groom: action.needed ? initialData : null,
        additionalGroom: null,
        priest: action.needed ? initialData : null,
        additionalPriest: null,
      };
      draft.accountsValid = validateAccountsInfo(draft.accounts);
    }),
  ),
  on(enableLetterAdditionalAccountsInfoAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts == null) {
        return;
      }

      const initialData = {
        name: '',
        bankName: '',
        no: '',
      };

      switch (action.target) {
        case '신랑측':
          draft.accounts.additionalGroom = initialData;
          break;
        case '신부측':
          draft.accounts.additionalPriest = initialData;
          break;
      }
    }),
  ),
  on(updateLetterAccountsTitleAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts != null) {
        draft.accounts.title = action.value;
      }
    }),
  ),
  on(updateLetterAccountsDescriptionAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts != null) {
        draft.accounts.description = action.value;
      }
    }),
  ),
  on(updateLetterGroomAccountInfoAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts.groom !== null) {
        draft.accounts.groom[action.key] = action.value;
        draft.accountsValid = validateAccountsInfo(draft.accounts);
      }
    }),
  ),
  on(updateLetterAdditionalGroomAccountInfoAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts.additionalGroom != null) {
        draft.accounts.additionalGroom[action.key] = action.value;
        draft.accountsValid = validateAccountsInfo(draft.accounts);
      }
    }),
  ),
  on(updateLetterPriestAccountInfoAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts.priest !== null) {
        draft.accounts.priest[action.key] = action.value;
        draft.accountsValid = validateAccountsInfo(draft.accounts);
      }
    }),
  ),
  on(updateLetterAdditionalPriestAccountInfoAction, (state, action) =>
    produce(state, (draft) => {
      if (draft.accounts.additionalPriest != null) {
        draft.accounts.additionalPriest[action.key] = action.value;
        draft.accountsValid = validateAccountsInfo(draft.accounts);
      }
    }),
  ),
  on(updateLetterThemeAction, (state, action) =>
    produce(state, (draft) => {
      draft.theme.theme = action.theme;
      draft.themeValid = validateThemeInfo(draft.theme);
    }),
  ),
  on(updateLetterOGInfoAction, (state, action) =>
    produce(state, (draft) => {
      draft.og.title = action.title;
      draft.ogValid = validateOGInfo(draft.og);
    }),
  ),
  on(toggleLetterAttendsEnabledAction, (state, action) =>
    produce(state, (draft) => {
      draft.attends.enabled = action.enabled;

      if (draft.attends.enabled) {
        draft.attends.title = '0';
        draft.attends.description = '0';
      }

      draft.attendsValid = validateAttendsInfo(draft.attends);
    }),
  ),
  on(updateLetterAttendsTitleAction, (state, action) =>
    produce(state, (draft) => {
      draft.attends.title = action.value;
      draft.attendsValid = validateAttendsInfo(draft.attends);
    }),
  ),
  on(updateLetterAttendsDescriptionAction, (state, action) =>
    produce(state, (draft) => {
      draft.attends.description = action.value;
      draft.attendsValid = validateAttendsInfo(draft.attends);
    }),
  ),
  on(toggleLetterGuestBooksEnabledAction, (state, action) =>
    produce(state, (draft) => {
      draft.guestBooks.enabled = action.enabled;
    }),
  ),
  on(updateLetterYoutubeLinkAction, (state, action) =>
    produce(state, (draft) => {
      draft.youtubeLink = action.link;
    }),
  ),
  on(updateLetterYoutubeLiveLinkAction, (state, action) =>
    produce(state, (draft) => {
      draft.youtubeLiveLink = action.link;
    }),
  ),
  on(naverstoreAction, (state) =>
    produce(state, (draft) => {
      draft.email = 'florencecard2@gmail.com';
      draft.groom = {
        name: '김진석',
        showPhone: true,
        phone1: '010',
        phone2: '1234',
        phone3: '5678',
        order: '장남',
        fatherName: '김창식',
        motherName: '박관숙',
        requireParentPhone: false,
      };
      draft.priest = {
        name: '이수정',
        showPhone: true,
        phone1: '010',
        phone2: '1234',
        phone3: '5678',
        order: '장녀',
        fatherName: '이정석',
        motherName: '김영희',
        requireParentPhone: false,
      };
      draft.place = {
        date: format(addDays(new Date(), 14), 'yyyy-MM-dd'),
        time: '오전 11시 30분',
        zipCode: '04340',
        address: '서울특별시 용산구 한남동 726-157',
        addressDetail: '남산예술원웨딩홀',
        phone1: '02',
        phone2: '795',
        phone3: '2244',
      };
      draft.note = {
        note1: '0',
        note2: '0',
      };
      draft.photo = {
        mainImage: 'https://assets.florencecard.me/images/samples/whiteflower1/main.png',
        secondImage: 'https://assets.florencecard.me/images/samples/yellowflower1/second.png',
        lastImage: 'https://assets.florencecard.me/images/samples/theme4/last.png',
        gallery: [
          'https://assets.florencecard.me/images/samples/gallery1.png',
          'https://assets.florencecard.me/images/samples/gallery2.png',
          'https://assets.florencecard.me/images/samples/gallery3.png',
          'https://assets.florencecard.me/images/samples/gallery4.png',
        ],
        galleryType: 'swipe',
      };

      draft.emailValid = isEmail(draft.email);
      draft.groomValid = validateGroomInfo(draft.groom);
      draft.priestValid = validatePriestInfo(draft.priest);
      draft.placeValid = validatePlaceInfo(draft.place);
      draft.noteValid = validateNoteInfo(draft.note);
      draft.photoValid = validatePhotoInfo(draft.photo);
    }),
  ),
);
