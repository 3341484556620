import { letterStorageKeys } from '~/models';
import { LetterState } from '~/store/reducers';
import { forEach, Nullable } from '~/utils';
import { createLocalStorage } from '~/utils/storage';

const storage = createLocalStorage();

const saveEmail = (letter: LetterState) => {
  if (letter.email != null) {
    storage.set(letterStorageKeys.email, letter.email);
  }
};

const saveIfNotEmpty = (key: string, value: Nullable<string>) => {
  if (value != null) {
    storage.set(key, value);
  }
};

const saveGroom = (letter: LetterState) => {
  storage.set(letterStorageKeys.groom.name, letter.groom.name);
  storage.set(letterStorageKeys.groom.showPhone, letter.groom.showPhone.toString());
  storage.set(letterStorageKeys.groom.phone1, letter.groom.phone1);
  storage.set(letterStorageKeys.groom.phone2, letter.groom.phone2);
  storage.set(letterStorageKeys.groom.phone3, letter.groom.phone3);
  storage.set(letterStorageKeys.groom.order, letter.groom.order);
  storage.set(letterStorageKeys.groom.fatherName, letter.groom.fatherName);
  storage.set(letterStorageKeys.groom.motherName, letter.groom.motherName);
  storage.set(
    letterStorageKeys.groom.requireParentPhone,
    letter.groom.requireParentPhone.toString(),
  );
  saveIfNotEmpty(letterStorageKeys.groom.fatherPhone1, letter.groom.fatherPhone1);
  saveIfNotEmpty(letterStorageKeys.groom.fatherPhone2, letter.groom.fatherPhone2);
  saveIfNotEmpty(letterStorageKeys.groom.fatherPhone3, letter.groom.fatherPhone3);
  saveIfNotEmpty(letterStorageKeys.groom.motherPhone1, letter.groom.motherPhone1);
  saveIfNotEmpty(letterStorageKeys.groom.motherPhone2, letter.groom.motherPhone2);
  saveIfNotEmpty(letterStorageKeys.groom.motherPhone3, letter.groom.motherPhone3);
};

const savePriest = (letter: LetterState) => {
  storage.set(letterStorageKeys.priest.name, letter.priest.name);
  storage.set(letterStorageKeys.priest.showPhone, letter.priest.showPhone.toString());
  storage.set(letterStorageKeys.priest.phone1, letter.priest.phone1);
  storage.set(letterStorageKeys.priest.phone2, letter.priest.phone2);
  storage.set(letterStorageKeys.priest.phone3, letter.priest.phone3);
  storage.set(letterStorageKeys.priest.order, letter.priest.order);
  storage.set(letterStorageKeys.priest.fatherName, letter.priest.fatherName);
  storage.set(letterStorageKeys.priest.motherName, letter.priest.motherName);
  storage.set(
    letterStorageKeys.priest.requireParentPhone,
    letter.priest.requireParentPhone.toString(),
  );
  saveIfNotEmpty(letterStorageKeys.priest.fatherPhone1, letter.priest.fatherPhone1);
  saveIfNotEmpty(letterStorageKeys.priest.fatherPhone2, letter.priest.fatherPhone2);
  saveIfNotEmpty(letterStorageKeys.priest.fatherPhone3, letter.priest.fatherPhone3);
  saveIfNotEmpty(letterStorageKeys.priest.motherPhone1, letter.priest.motherPhone1);
  saveIfNotEmpty(letterStorageKeys.priest.motherPhone2, letter.priest.motherPhone2);
  saveIfNotEmpty(letterStorageKeys.priest.motherPhone3, letter.priest.motherPhone3);
};

const savePlace = (letter: LetterState) => {
  storage.set(letterStorageKeys.place.date, letter.place.date);
  storage.set(letterStorageKeys.place.time, letter.place.time);
  storage.set(letterStorageKeys.place.zipCode, letter.place.zipCode);
  storage.set(letterStorageKeys.place.address, letter.place.address);
  storage.set(letterStorageKeys.place.addressDetail, letter.place.addressDetail);
  storage.set(letterStorageKeys.place.showPlace, (letter.place.showPlace ?? true).toString());
  storage.set(letterStorageKeys.place.phone1, letter.place.phone1);
  storage.set(letterStorageKeys.place.phone2, letter.place.phone2);
  storage.set(letterStorageKeys.place.phone3, letter.place.phone3);

  if (letter.place.x != null) {
    storage.set(letterStorageKeys.place.x, letter.place.x);
  }

  if (letter.place.y != null) {
    storage.set(letterStorageKeys.place.y, letter.place.y);
  }
};

const saveNote = (letter: LetterState) => {
  storage.set(letterStorageKeys.note.note1, letter.note.note1);
  storage.set(letterStorageKeys.note.note2, letter.note.note2);
};

const savePhoto = (letter: LetterState) => {
  saveIfNotEmpty(letterStorageKeys.photo.main, letter.photo.mainImage);
  saveIfNotEmpty(letterStorageKeys.photo.second, letter.photo.secondImage);
  saveIfNotEmpty(letterStorageKeys.photo.last, letter.photo.lastImage);
  storage.set(letterStorageKeys.photo.gallery, JSON.stringify(letter.photo.gallery));
  saveIfNotEmpty(letterStorageKeys.photo.galleryType, letter.photo.galleryType);
};

const saveWayToCome = (letter: LetterState) => {
  storage.set(letterStorageKeys.wayToCome, JSON.stringify(letter.wayToCome));
};

const saveAccounts = (letter: LetterState) => {
  storage.set(letterStorageKeys.accounts, JSON.stringify(letter.accounts));
};

const saveTheme = (letter: LetterState) => {
  if (letter.theme.theme !== null) {
    storage.set(letterStorageKeys.theme.theme, letter.theme.theme);
  }
};

const saveOG = (letter: LetterState) => {
  storage.set(letterStorageKeys.og.title, letter.og.title);
};

const saveAttends = (letter: LetterState) => {
  storage.set(letterStorageKeys.attends, JSON.stringify(letter.attends));
};

const saveGuestBooks = (letter: LetterState) => {
  storage.set(letterStorageKeys.guestBooks, JSON.stringify(letter.guestBooks));
};

const saveYoutubeLinks = (letter: LetterState) => {
  storage.set(letterStorageKeys.youtubeLink, letter.youtubeLink);
  storage.set(letterStorageKeys.youtubeLiveLink, letter.youtubeLiveLink);
};

export function saveLetterStorage(letter: LetterState) {
  saveEmail(letter);
  saveGroom(letter);
  savePriest(letter);
  savePlace(letter);
  saveWayToCome(letter);
  saveNote(letter);
  savePhoto(letter);
  saveAccounts(letter);
  saveAttends(letter);
  saveTheme(letter);
  saveOG(letter);
  saveGuestBooks(letter);
  saveYoutubeLinks(letter);
}

export function clearLetterStorage() {
  forEach(letterStorageKeys, (_, keys) => {
    if (typeof keys === 'string') {
      storage.delete(keys);
    } else {
      forEach(keys, (_, key) => {
        storage.delete(key);
      });
    }
  });
}
