import type { AnyObject } from '@florencecard-lib/type-utils';
import { ua } from '@florencecard-lib/ua';
import React, { ComponentClass, FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { isProd } from '~/env';
import { rootEpic } from './epics';
import { rootReducer, RootState } from './reducers';
import { Action } from './seed-work';

const id = function <T>(x: T) {
  return x;
};

const withDevTools = (() => {
  if (!ua.isBrowser || isProd) {
    return id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? id;
})();

function withReduxStore<State>(configureStore: () => Store<State, Action>) {
  const store = configureStore();

  return function <Props = AnyObject>(
    AppComponent: FunctionComponent<Props> | ComponentClass<Props>,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Comp = AppComponent as any;

    function AppWithReduxStore(props: Props) {
      return (
        <Provider store={store}>
          <Comp {...props} />
        </Provider>
      );
    }

    return AppWithReduxStore;
  };
}

const epicMiddleware = createEpicMiddleware<Action, Action, RootState>();

export const withStore = withReduxStore(() => {
  const store = createStore(rootReducer, undefined, withDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
});
