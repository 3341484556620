import { combineReducers } from 'redux';
import { letterReducer, LetterState } from './letter';

export * from './letter';

export type RootState = Readonly<{
  letter: LetterState;
}>;

export const rootReducer = combineReducers({
  letter: letterReducer,
});
