import { ModalProvider, PortalProvider, ResponsiveProvider } from '@florencecard/components';
import { init as initSentry } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { RecoilRoot } from 'recoil';
import type { DeepRequired } from 'utility-types';
import IntroHeader from '~/components/IntroHeader';
import { isNextPage, NextPageMetadata, NextPageMetadataData } from '~/core';
import { isProd, publicEnv } from '~/env';
import NotFoundErrorBoundary from '~/exceptions/NotFoundErrorBoundary';
import { withStore } from '~/store';

export function reportWebVitals({ id, name, value }: NextWebVitalsMetric) {
  window.gtag?.('event', name, {
    event_category: 'Web Vitals',
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
    non_interaction: true,
  });
}

const defaultMetadata: DeepRequired<NextPageMetadata> = {
  title: '플로렌스카드',
  description: '쉽고 예쁜 모바일 청첩장 만들기, 플로렌스카드',
  author: '플로렌스랩',
  keywords: '플로렌스카드, 청첩장, 디지털 청첩장, 모바일 청첩장, 결혼',
  showHeader: true,
  ogImage: 'https://assets.florencecard.me/images/og.png',
  ogImageType: 'image/png',
};

const Identifier: FunctionComponent<{ children: ReactNode }> = (props) => <>{props.children}</>;

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const { metadata, Wrapper } = useMemo(() => {
    const customMetadata = isNextPage(Component) ? Component.metadata : undefined;
    const metadata: DeepRequired<NextPageMetadata> = {
      ...defaultMetadata,
      ...customMetadata,
    };

    const Wrapper = (isNextPage(Component) ? Component.Wrapper : Identifier) ?? Identifier;

    return {
      metadata,
      Wrapper,
    };
  }, [Component]);

  const pageStringResolver = useCallback(
    function <Result extends string | boolean | number | undefined | null>(
      data: NextPageMetadataData<Result>,
    ) {
      if (typeof data === 'function') {
        return data({
          pathname: router.pathname,
          pageProps,
        });
      }

      return data;
    },
    [router, pageProps],
  );

  useEffect(() => {
    initSentry({
      enabled: isProd,
      dsn: 'https://ee7e17df400c49bdbfc5d7ca0e4899b8@o1394240.ingest.sentry.io/6716220',
      release: publicEnv.sentry.release,
      integrations: [new Integrations.BrowserTracing()],
      ignoreErrors: [
        // https://sentry.io/organizations/wedding-master/issues/1594328233
        /코드 V813/,
        // https://sentry.io/organizations/wedding-master/issues/1619935294l
        /사용자가 결제를 취소/,
      ],
    });
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover"
        />
        <title>{pageStringResolver(metadata.title)}</title>

        <meta property="og:title" content={pageStringResolver(metadata.title)} />
        <meta name="description" content={pageStringResolver(metadata.description)} />
        <meta property="og:description" content={pageStringResolver(metadata.description)} />
        <meta property="og:image" content={pageStringResolver(metadata.ogImage)} />
        <meta property="og:image:type" content={pageStringResolver(metadata.ogImageType)} />
        <meta name="keywords" content={pageStringResolver(metadata.keywords)} />
        <meta name="author" content={pageStringResolver(metadata.author)} />
      </Head>
      <NotFoundErrorBoundary>
        <RecoilRoot>
          <ResponsiveProvider mediaQueries={['@media(min-width: 700px)']}>
            <PortalProvider>
              <ModalProvider>
                {metadata.showHeader ? <IntroHeader /> : null}
                <Wrapper>
                  <Component {...pageProps} />
                </Wrapper>
              </ModalProvider>
            </PortalProvider>
          </ResponsiveProvider>
        </RecoilRoot>
      </NotFoundErrorBoundary>
    </>
  );
}

export default withStore(App);
