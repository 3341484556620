import { combineEpics } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { saveLetterInfoAction } from '~/store/actions';
import { ofType } from '~/store/seed-work';
import type { Epic } from '~/store/types';
import { saveLetterStorage } from '~/store/utils';
import { canUseLocalStorage } from '~/utils/storage';

const saveLetterInfoEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(saveLetterInfoAction),
    tap((action) => {
      const showAlert = action.showAlert ?? true;

      if (!canUseLocalStorage()) {
        alert(
          '쿠키차단을 해제 시켜야 임시저장이 가능합니다. (시크릿탭에서는 임시저장이 불가합니다)',
        );
        return;
      }

      const { letter } = state$.value;
      saveLetterStorage(letter);

      if (showAlert) {
        alert('임시저장이 완료되었습니다.');
      }
    }),
    ignoreElements(),
  );

export const letterEpic = combineEpics(saveLetterInfoEpic);
