import type { Nullable } from '~/utils/types';

export function isNumberString(value: Nullable<string>) {
  return value != null && !Number.isNaN(Number(value));
}

// eslint-disable-next-line no-useless-escape
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmail(value: Nullable<string>) {
  return value != null ? emailRegExp.test(value) : false;
}

// 사업자등록번호는 10자리 숫자
export function isBusinessNumber(value: string) {
  return /\d{10}/.test(value);
}

export function validateLength(min: number, max: number) {
  return (value: Nullable<string>) => {
    if (value == null) {
      return false;
    }

    const length = value.length;

    return min <= length && length <= max;
  };
}
