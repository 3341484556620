import { ErrorBoundary } from '@florencecard-lib/error-boundary';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import { NotFoundError } from '~/exceptions/NotFoundError';

function NotFoundErrorBoundaryFallback({ error, reset }: { error: Error; reset: () => void }) {
  const router = useRouter();

  useEffect(() => {
    if (error instanceof NotFoundError) {
      reset();
      error.replace ? router.replace(error.redirectUrl) : router.push(error.redirectUrl);
    }
  }, [error, reset, router]);

  return <></>;
}

const ignoreError = (error: Error) => !(error instanceof NotFoundError);
const renderFallback = (props: { error: Error; reset: () => void }) => (
  <NotFoundErrorBoundaryFallback {...props} />
);

export default function NotFoundErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary renderFallback={renderFallback} ignoreError={ignoreError}>
      {children}
    </ErrorBoundary>
  );
}
