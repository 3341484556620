/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format } from 'date-fns';
import {
  isLetterAccountsDescriptionCustom,
  isLetterAccountsTitleCustom,
  isLetterAttendsDescriptionCustom,
  isLetterAttendsTitleCustom,
  isLetterNote1Custom,
  isLetterNote2Custom,
  letterAccountsDescriptionValues,
  letterAccountsTitleValues,
  letterAttendsDescriptionValues,
  letterAttendsTitleValues,
  LetterData,
  letterNote1Values,
  letterNote2Values,
  LetterOGTitleVariant,
} from '~/models';
import { LetterState } from '~/store/reducers';

export function createOGTitle(data: {
  variant: LetterOGTitleVariant;
  groomName: string;
  priestName: string;
}) {
  const { variant, groomName, priestName } = data;

  switch (variant) {
    case LetterOGTitleVariant['김신랑, 박신부의 결혼을 축하해주세요.']:
      return `${groomName}, ${priestName}의 결혼을 축하해주세요.`;
    case LetterOGTitleVariant['김신랑♥박신부 결혼합니다.']:
      return `${groomName}♥${priestName} 결혼합니다.`;
  }
}

export function letterStateToData(state: LetterState) {
  if (
    !state.emailValid ||
    !state.groomValid ||
    !state.noteValid ||
    !state.photoValid ||
    !state.placeValid ||
    !state.priestValid
  ) {
    return null;
  }

  const data: LetterData = {
    groom: state.groom,
    priest: state.priest,
    place: {
      ...state.place,
      date: format(new Date(state.place.date), 'yyyy-MM-dd'),
    },
    note: {
      note1: isLetterNote1Custom(state.note.note1)
        ? state.note.note1
        : letterNote1Values[Number(state.note.note1)],
      note2: isLetterNote2Custom(state.note.note2)
        ? state.note.note2
        : letterNote2Values[Number(state.note.note2)],
    },
    photo: {
      mainImage: state.photo.mainImage!,
      secondImage: state.photo.secondImage!,
      lastImage: state.photo.lastImage!,
      gallery: state.photo.gallery,
      galleryType: state.photo.galleryType,
    },
    wayToCome: state.wayToCome,
    accounts: {
      title:
        state.accounts.title != null
          ? isLetterAccountsTitleCustom(state.accounts.title)
            ? state.accounts.title
            : letterAccountsTitleValues[Number(state.accounts.title)]
          : null,
      description:
        state.accounts.description != null
          ? isLetterAccountsDescriptionCustom(state.accounts.description)
            ? state.accounts.description
            : letterAccountsDescriptionValues[Number(state.accounts.description)]
          : null,
      groom: state.accounts.groom,
      priest: state.accounts.priest,
      additionalGroom: state.accounts.additionalGroom,
      additionalPriest: state.accounts.additionalPriest,
    },
    attends: {
      enabled: state.attends.enabled,
      title: isLetterAttendsTitleCustom(state.attends.title)
        ? state.attends.title
        : letterAttendsTitleValues[Number(state.attends.title)],
      description: isLetterAttendsDescriptionCustom(state.attends.description)
        ? state.attends.description
        : letterAttendsDescriptionValues[Number(state.attends.description)],
    },
    guestBooks: {
      enabled: state.guestBooks.enabled,
    },
    og: {
      title: createOGTitle({
        variant: state.og.title,
        groomName: state.groom.name,
        priestName: state.priest.name,
      }),
    },
    youtubeLink: state.youtubeLink,
    youtubeLiveLink: state.youtubeLiveLink,
  };

  return data;
}
