import React, {
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ModalContext, ModalContextValue } from './context';
import type { CommonModalProps, RenderModal } from './interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Resolver<T = any> = (returnValue?: T) => void;
let resolver: Resolver = () => {};

export function ModalProvider({ children }: { children: ReactNode }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalNode, setModalNode] = useState<ReactNode>(null);

  const open = useCallback(<T, P extends CommonModalProps<T>>(render: RenderModal<T, P>) => {
    return new Promise<T | undefined>((resolve) => {
      resolver = resolve;

      const element = render((returnValue) => {
        resolver(returnValue);
        setIsModalOpen(false);
      });

      setModalNode(element);
      setIsModalOpen(true);
    });
  }, []);

  const close = useCallback(() => {
    setModalNode(null);
    setIsModalOpen(false);
  }, []);

  const context = useMemo(
    (): ModalContextValue => ({
      open,
      close,
    }),
    [open, close],
  );

  return (
    <ModalContext.Provider value={context}>
      {children}
      {isValidElement(modalNode)
        ? cloneElement(modalNode, {
            ...modalNode.props,
            open: isModalOpen,
          })
        : null}
    </ModalContext.Provider>
  );
}
